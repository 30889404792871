import { Badge } from "../../../../components/badge"
import { StatusLight } from "../../../../components/status-light"
import type { BrandOptionFragment, CompanySearchEntityFragment } from "../../../../graphql/types"
import { BankIcon } from "../../../../icons/bank-icon"
import { DEFAULT_CDN_IMAGE, getCDNImage } from "../../utils/cnd-image"

interface CompanyListItemProps {
	company: CompanySearchEntityFragment
	brand: BrandOptionFragment | undefined
}

const CompanyListItem = ({ company, brand }: CompanyListItemProps) => {
	const { ref, activeOnBrands, logo, country, activities, name } = company
	const isActiveOnBrand = activeOnBrands?.some((element) => element.ref === brand?.ref)

	const filteredActivities = activities.filter((a) => a.startsWith("ACTIVITY_"))

	return (
		<div className="flex gap-x-3">
			<span className="basis-16 shrink-0">
				<img
					alt="Logo"
					className="rounded aspect-poster object-cover w-16"
					src={logo ? getCDNImage(`/c_74_100/${logo.path}`) : DEFAULT_CDN_IMAGE}
				/>
			</span>
			<div className="flex flex-col gap-y-1">
				<div className="font-semibold items-baseline flex gap-2 text-wrap">
					<span>
						<BankIcon className="shrink-0" size="S" />
					</span>
					{brand !== undefined && (
						<span>
							<StatusLight variant={isActiveOnBrand ? "positive" : "negative"} />
						</span>
					)}
					<span>
						{country ? `${name} (${country.name})` : name} <span className="text-xs">({ref})</span>
					</span>
				</div>
				{filteredActivities.length > 0 && (
					<div className="flex flex-wrap gray-500 gap-1">
						{filteredActivities.map((activity) => (
							<Badge key={activity}>{activity}</Badge>
						))}
					</div>
				)}
			</div>
		</div>
	)
}

export default CompanyListItem
