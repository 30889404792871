import { Button as MuiButton } from "@mui/base/Button"
import { styled } from "@mui/material"
import type { ButtonHTMLAttributes, ReactNode } from "react"

interface ButtonBaseVariants extends ButtonHTMLAttributes<HTMLButtonElement> {
	rightIcon?: ReactNode
	leftIcon?: ReactNode
	variant?: "contained"
	color?: "primary" | "warning" | "danger"
	fullWidth?: boolean
}

export const ButtonBase = styled(MuiButton)<ButtonBaseVariants>(({ theme }) => ({
	fontSize: "0.875rem",
	width: "max-content",
	height: "2rem",
	display: "flex",
	cursor: "pointer",
	alignItems: "center",
	borderRadius: theme.shape.borderRadius,
	padding: `0 ${theme.spacing(1.5)}`,
	border: "unset",
	gap: theme.spacing(1),
	variants: [
		{
			props: { disabled: true },
			style: { pointerEvents: "none", opacity: 0.5 },
		},
		{
			props: { fullWidth: true },
			style: { width: "100%" },
		},
		{
			props: { variant: "contained", color: "primary" },
			style: {
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
				transition: theme.transitions.create(["background-color", "transform"], {
					duration: theme.transitions.duration.short,
				}),
				"&:hover": {
					backgroundColor: theme.palette.primary.light,
				},
			},
		},
		{
			props: { variant: "contained", color: "warning" },
			style: {
				backgroundColor: theme.palette.warning.main,
				color: theme.palette.warning.contrastText,
				transition: theme.transitions.create(["background-color", "transform"], {
					duration: theme.transitions.duration.short,
				}),
				"&:hover": {
					backgroundColor: theme.palette.warning.light,
				},
			},
		},
		{
			props: { variant: "contained", color: "danger" },
			style: {
				backgroundColor: theme.palette.error.main,
				color: theme.palette.error.contrastText,
				transition: theme.transitions.create(["background-color", "transform"], {
					duration: theme.transitions.duration.short,
				}),
				"&:hover": {
					backgroundColor: theme.palette.error.light,
				},
			},
		},
	],
}))

export const Button = ({ children, leftIcon, rightIcon, ...props }: ButtonBaseVariants) => {
	return (
		<ButtonBase {...props}>
			{leftIcon && <span className="left-icon">{leftIcon}</span>}
			{children}
			{rightIcon && <span className="right-icon">{rightIcon}</span>}
		</ButtonBase>
	)
}
