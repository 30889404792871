import clsx from "clsx"
import type { ButtonHTMLAttributes, ComponentType } from "react"
import { createElement, useMemo } from "react"

import { FormChangeState, useFormChangeState } from "@hooks/use-form-change-state"

import { LoaderIcon } from "../icons/loader-icon"
import { WaitIcon } from "../icons/wait-icon"
import type { ButtonColor } from "./button.types"
import type { IconPropsWithoutChildren } from "./icon"

export type ButtonsStyleProps = Pick<
	ButtonProps,
	"disabled" | "loading" | "color" | "notRounded" | "active" | "isEmphasized"
> & {
	variant?: "button" | "link"
	hasIcon?: boolean
	isIcon?: boolean
	size?: "default" | "s" | "ss" | "xs"
}
export function useButtonStyle({
	disabled,
	loading,
	color,
	active,
	hasIcon = false,
	isIcon = false,
	notRounded,
	variant = "button",
	isEmphasized = false,
	size = "default",
}: ButtonsStyleProps) {
	return useMemo(() => {
		const hasOpacity = disabled || loading
		const isGrayedIsh = color && (["grayed", "transparent"] as ButtonColor[]).includes(color)

		return [
			"inline-flex items-center justify-center select-none no-underline transition-colors text-sm gap-x-1 relative",
			{
				"opacity-50": hasOpacity,
				"cursor-wait": loading,
				"cursor-not-allowed": disabled,
			},
			{
				"h-8 pr-3": !isIcon,
				"pl-3": !(isIcon || hasIcon),
				"pl-2": !isIcon && hasIcon,
				"p-1.5": isIcon,
			},
			...(variant === "link" ? ["hover:underline"] : []),
			...(variant === "button"
				? [
						{
							rounded: !notRounded,
							"border border-solid": true,
							"border-transparent": color !== "grayed",
						},
						{
							"bg-gray-200": !color,
						},
						{
							"bg-gray-200 text-gray-500": color === "inactive",
							"hover:bg-gray-100": color === "inactive" && !hasOpacity,
						},
						{
							"bg-gray-400 text-white": color === "secondary",
							"hover:bg-gray-200": color === "secondary" && !hasOpacity,
						},
						{
							"bg-blue-500 text-white": color === "info" || color === "primary",
							"hover:bg-blue-400": (color === "info" || color === "primary") && !hasOpacity,
						},
						{
							"bg-red-500 text-white": color === "danger",
							"hover:bg-red-400": color === "danger" && !hasOpacity,
						},
						{
							"bg-emerald-500 text-white": color === "success",
							"hover:bg-emerald-400": color === "success" && !hasOpacity,
						},
						{
							" bg-amber-100 text-amber-700": color === "warning",
							"hover:bg-amber-300": color === "warning" && !hasOpacity,
						},
						{
							"border-gray-300": color === "grayed",
							"bg-transparent text-gray-600": isGrayedIsh,
							"hover:bg-gray-100": isGrayedIsh && !active && !hasOpacity,
							"bg-gray-300": isGrayedIsh && active && !hasOpacity,
							"!bg-blue-300 text-white": active,
						},
						{
							"before:animate-width before:content-[''] before:rounded before:absolute before:bottom-0 before:left-0 before:w-0 before:h-full before:bg-white before:opacity-10":
								isEmphasized,
						},
						{
							"size-4 !p-[2px]": size === "xs",
							"size-5 !p-1": size === "ss",
							"size-6 !p-0": size === "s",
						},
					]
				: []),
		]
	}, [disabled, loading, color, isIcon, hasIcon, notRounded, active, isEmphasized, variant, size])
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	loading?: boolean
	pending?: boolean
	active?: boolean
	color?: ButtonColor
	notRounded?: boolean
	icon?: ComponentType<IconPropsWithoutChildren>
	iconPlacement?: "left" | "right"
	isEmphasized?: boolean
	size?: ButtonsStyleProps["size"]
}

export function Button({
	children,
	className,
	active = false,
	disabled = false,
	pending = false,
	loading = false,
	color = "info",
	iconPlacement = "left",
	notRounded,
	icon,
	isEmphasized = false,
	...rest
}: ButtonProps) {
	const formState = useFormChangeState()

	const buttonStyle = useButtonStyle({
		color,
		disabled,
		loading,
		notRounded,
		hasIcon: icon !== undefined,
		active,
		isEmphasized: isEmphasized && formState === FormChangeState.DIRTY,
	})

	return (
		<button type="button" className={clsx(className, buttonStyle)} disabled={disabled || loading} {...rest}>
			{loading && <LoaderIcon color="primary" />}
			{pending && <WaitIcon animate />}
			{icon
				? !(loading || pending) &&
					(iconPlacement === "left" ? (
						<>
							{createElement(icon)}
							{children}
						</>
					) : (
						<>
							{children}
							{createElement(icon)}
						</>
					))
				: children}
		</button>
	)
}

/**
 * Icon button is an icon wrapper with defaults to secondary color
 */
export function IconButton({
	className,
	disabled = false,
	loading = false,
	color = "info",
	notRounded,
	active = false,
	isEmphasized,
	children,
	size,
	...rest
}: ButtonProps) {
	const buttonStyle = useButtonStyle({
		color,
		size,
		disabled,
		loading,
		isIcon: true,
		notRounded,
		active,
		isEmphasized,
	})
	const isDisabled = disabled || loading

	return (
		<button
			type="button"
			className={clsx(className, buttonStyle, "text-nowrap", {
				"max-h-[30px] min-h-[30px]":
					Boolean(children) && Boolean((className ? !className.includes("size-") : true) && !size),
			})}
			disabled={isDisabled}
			{...rest}
		>
			{children}
		</button>
	)
}
