import type { BrandOptionFragment, TheaterSearchEntityFragment } from "../../../../graphql/types"
import { TheatersIcon } from "../../../../icons/theaters-icon"
import { DEFAULT_CDN_IMAGE, getCDNImage } from "../../utils/cnd-image"

interface TheaterListItemProps {
	theater: TheaterSearchEntityFragment
	brand?: BrandOptionFragment
}

const TheaterListItem = ({ theater }: TheaterListItemProps) => {
	const { name, logo, theaterCountry: country, code } = theater

	return (
		<div className="flex gap-x-3">
			<span className="basis-16 shrink-0">
				<img
					alt="Logo"
					className="rounded aspect-poster object-cover w-16"
					src={logo ? getCDNImage(`/c_74_100/${logo.path}`) : DEFAULT_CDN_IMAGE}
				/>
			</span>
			<div className="flex flex-col gap-y-1">
				<div className="font-semibold items-baseline flex gap-2 text-wrap">
					<span>
						<TheatersIcon className="shrink-0" size="S" />
					</span>
					<span>
						{country ? `${name} (${country.name})` : name} <span className="text-xs">({code})</span>
					</span>
				</div>
			</div>
		</div>
	)
}

export default TheaterListItem
