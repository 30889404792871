import { Switch as MuiSwitch, switchClasses } from "@mui/base/Switch"
import { styled } from "@mui/system"

export const Switch = styled(MuiSwitch)(({ theme }) => ({
	fontSize: 0,
	position: "relative",
	display: "inline-block",
	width: "32px",
	height: "20px",
	background: theme.palette.grey[300],
	borderRadius: "10px",
	margin: "10px",
	cursor: "pointer",

	[`& .${switchClasses.thumb}`]: {
		display: "block",
		width: "14px",
		height: "14px",
		top: "3px",
		left: "3px",
		borderRadius: "16px",
		backgroundColor: "#fff",
		position: "relative",
		transition: "all 200ms ease",
	},

	[`& .${switchClasses.input}`]: {
		cursor: "inherit",
		position: "absolute",
		width: "100%",
		height: "100%",
		top: 0,
		left: 0,
		opacity: 0,
		zIndex: 1,
		margin: 0,
	},

	[`&.${switchClasses.disabled}`]: {
		opacity: 0.4,
		cursor: "not-allowed",
	},

	[`&.${switchClasses.checked}`]: {
		background: theme.palette.primary.main,

		[`& .${switchClasses.thumb}`]: {
			left: "14px",
			top: "3px",
			backgroundColor: "#fff",
		},
	},

	[`&.${switchClasses.focusVisible} .${switchClasses.thumb}`]: {
		backgroundColor: "rgb(255 255 255 / 1)",
		boxShadow: "0 0 1px 8px rgb(0 0 0 / 0.25)",
	},

	variants: [
		{
			props: { disabled: true },
			style: {
				pointerEvents: "none",
				opacity: 0.5,
			},
		},
	],
}))
