import AppsOutageIcon from "@mui/icons-material/AppsOutage"
import { Box, Skeleton, alpha, styled } from "@mui/material"
import {
	DataGridPremium,
	type DataGridPremiumProps,
	type GridLoadingOverlayProps,
	type GridValidRowModel,
} from "@mui/x-data-grid-premium"
import React from "react"

import { CircularProgress } from "./CircularProgress"
import { LinearProgress } from "./LinearProgress"

export const StyledTable = styled(DataGridPremium)(({ theme: { palette } }) => ({
	border: "unset",
	borderRadius: 0,
	"@keyframes loading": {
		"0%": { backgroundColor: alpha(palette.grey[500], 0.1) },
		"100%": { backgroundColor: alpha(palette.grey[500], 0.1) },
	},
	"& .danger:hover": {
		background: palette.red[50],
	},
	"& .danger": {
		pointerEvents: "none",
		background: palette.red[50],
		opacity: 0.8,
	},
	"& .MuiDataGrid-columnHeader": {
		borderTopWidth: "1px",
		borderColor: palette.grey[300],
		borderRadius: "0 !important",
		background: palette.grey[100],
	},
	"& .MuiDataGrid-columnHeaderTitle": {
		fontWeight: 600,
	},
	"& .MuiDataGrid-cell": {
		alignContent: "center",
	},
	"& .MuiDataGrid-cell > *": {
		verticalAlign: "middle",
	},
	"& .MuiDataGrid-overlayWrapper:has(.no-results), & .MuiDataGrid-main:not(:has(.MuiDataGrid-row)) .MuiDataGrid-overlayWrapper:has(.loader)":
		{
			minHeight: "3rem",
		},
	"& .MuiDataGrid-overlayWrapperInner:has(.loader)": {
		backgroundColor: alpha(palette.grey[500], 0),
		animationName: "loading",
		animationDuration: "2s",
		animationIterationCount: "infinite",
		animationTimingFunction: "linear",
	},
})) as typeof DataGridPremium

// biome-ignore lint/suspicious/noExplicitAny: extracted from original lib
export type TableProps<R extends GridValidRowModel = any> = DataGridPremiumProps<R> & { pending?: boolean }

// biome-ignore lint/suspicious/noExplicitAny: extracted from original lib
export const Table = function Table<R extends GridValidRowModel = any>(props: TableProps<R>) {
	return (
		<StyledTable
			pageSizeOptions={[10, 20, 30, 50, 100]}
			sortingMode="server"
			paginationMode="server"
			columnHeaderHeight={50}
			rowHeight={50}
			pagination
			initialState={{
				density: "compact",
			}}
			slots={{
				noRowsOverlay: TableNoRowsOverlay,
				noResultsOverlay: TableNoRowsOverlay,
				loadingOverlay: TableLoaderOverlay,
			}}
			slotProps={{
				loadingOverlay: { pending: props.pending, variant: "linear-progress" },
			}}
			{...props}
		/>
	)
}

const GridOverlay = styled("div")(() => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	height: "100%",
	width: "100%",
}))

const TableNoRowsOverlay = React.memo(function TableNoRowsOverlay() {
	return (
		<GridOverlay>
			<AppsOutageIcon fontSize="large" />
			<Box className="no-results" sx={{ mt: 1, maxWidth: "20rem", textAlign: "center" }}>
				<Box>No results to display</Box>
				(according to current filters and available data)
			</Box>
		</GridOverlay>
	)
})

const TableLoaderOverlay = React.memo(function TableLoaderOverlay({
	pending = false,
	variant = "circular-progress",
}: GridLoadingOverlayProps) {
	const isLinear = variant === "linear-progress"
	const Comp = isLinear ? LinearProgress : variant === "circular-progress" ? CircularProgress : Skeleton
	return (
		<GridOverlay sx={{ justifyContent: isLinear ? "flex-start" : undefined }}>
			<Comp
				className="loader"
				{...(pending ? { color: "secondary" } : {})}
				sx={{ width: variant === "circular-progress" ? undefined : "100%" }}
			/>
		</GridOverlay>
	)
})
