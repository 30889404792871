import type { EntityType, Movie, News, Person, Series, SeriesSeason } from "../graphql/types"

export type Entities = keyof typeof EntityType

export type MinimumIdentifiableEntity = {
	__typename: string
	name?: string | null
	title?: string | null
	id?: string | null
	ref?: string | null
}

export type PersonEntity = MinimumIdentifiableEntity & Pick<Person, "lastName" | "firstName">

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isPersonEntity = (v: any): v is PersonEntity => "firstName" in v && "lastName" in v && "ref" in v

export type AuthorEntity = MinimumIdentifiableEntity & { name: string }
// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isAuthorEntity = (v: any): v is AuthorEntity => "ref" in v && "name" in v

export type MovieEntity = MinimumIdentifiableEntity &
	Pick<Movie, "title"> & {
		movieTitle?: string
	}

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isMovieEntity = (v: any): v is MovieEntity =>
	("title" in v || "movieTitle" in v) && v.__typename === "Movie"

export type NewsEntity = MinimumIdentifiableEntity & Pick<News, "title">

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isNewsEntity = (v: any): v is NewsEntity => "title" in v && v.__typename === "News"

export type SeriesEntity = MinimumIdentifiableEntity &
	Pick<Series, "title"> & {
		seriesTitle?: string
	}

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isSeriesEntity = (v: any): v is SeriesEntity =>
	("title" in v || "seriesTitle" in v) && v.__typename === "Series"

export type SeriesSeasonEntity = MinimumIdentifiableEntity & Pick<SeriesSeason, "series" | "number">

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isSeriesSeasonEntity = (v: any): v is SeriesSeasonEntity =>
	"title" in v.series && v.__typename === "SeriesSeason"

export type SeriesEpisodeEntity = MinimumIdentifiableEntity & {
	number?: number
	episodeNumber?: number
	title?: string
	episodeTitle?: string
	series: SeriesEntity
	season?: SeriesSeasonEntity
}
// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isSeriesEpisodeEntity = (v: any): v is SeriesEpisodeEntity =>
	v.__typename === "SeriesEpisode" && ("number" in v || "episodeNumber" in v) && "series" in v

export type MinimumReferentiableEntity = MinimumIdentifiableEntity & {
	ref: string
}

export type BrandLocaleDataEntity = {
	title?: string | null
	brandsData?: { title: string | null }[] | null
	localesData?: { title: string | null }[] | null
	brandData?: { title: string | null } | null
	localeData?: { title: string | null } | null
}

export const isBrandLocaleDataEntity = (
	// biome-ignore lint/suspicious/noExplicitAny: typeguard
	v: any,
): v is BrandLocaleDataEntity =>
	"title" in v && ("brandsData" in v || "localesData" in v || "brandData" in v || "localeData" in v)

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isMinimumReferentiableEntity = (v: any): v is MinimumReferentiableEntity => "ref" in v

export type FestivalRelatedEntity = MinimumIdentifiableEntity & {
	year: number
	editionNumber: number
	name?: string
	editionName?: string
	festival: {
		ref: string
		name?: string
		festivalName?: string
	}
}

export type FestivalCompetitionRelatedEntity = MinimumIdentifiableEntity & {
	ref: string
	festivalEdition: FestivalRelatedEntity
}

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isFestivalEditionEntity = (v: any): v is FestivalRelatedEntity =>
	("name" in v || "editionName") &&
	"festival" in v &&
	("name" in v.festival || "festivalName" in v.festival) &&
	"ref" in v.festival

export type FestivalCompetitionEntity = MinimumIdentifiableEntity & {
	ref: string
} & Pick<FestivalRelatedEntity, "festival"> & {
		festivalEdition?: FestivalRelatedEntity
	}

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isFestivalCompetitionEntity = (v: any): v is FestivalCompetitionEntity =>
	"name" in v && isFestivalEditionEntity(v)

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isFestivalEditionRelatedEntity = (v: any): v is FestivalCompetitionRelatedEntity =>
	"name" in v &&
	"ref" in v &&
	"year" in v &&
	"festivalEdition" in v &&
	"ref" in v.festivalEdition &&
	"festival" in v.festivalEdition &&
	"ref" in v.festivalEdition.festival

export type FestivalEntity = MinimumIdentifiableEntity & {
	name?: string
	festivalName?: string
}

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isFestivalEntity = (v: any): v is FestivalEntity => "ref" in v && ("name" in v || "festivalName" in v)

export type FestivalAwardEntity = MinimumIdentifiableEntity & {
	festival: {
		ref: string
	}
}
// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isFestivalAwardEntity = (v: any): v is FestivalAwardEntity =>
	"name" in v && "festival" in v && "ref" in v.festival

export type CompanyRelatedEntity = MinimumIdentifiableEntity & {
	company: MinimumIdentifiableEntity
}

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isCompanyRelatedEntity = (v: any): v is CompanyRelatedEntity => "company" in v && "ref" in v

export type CompanyEntity = Pick<MinimumIdentifiableEntity, "ref"> & {
	name: string
	logo: string
	country?: {
		id: string
		name: string
	}
	companyCountry?: {
		id: string
		ref: string
		name: string
	}
	activities: string[]
}

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isCompanyEntity = (v: any): v is CompanyEntity => "name" in v && "ref" in v

export type TheaterEntity = Pick<MinimumIdentifiableEntity, "ref"> & {
	code: string
	country?: {
		name: string
	}
}

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isTheaterEntity = (v: any): v is TheaterEntity => "code" in v || "ref" in v

export type CompanyStaffEntity = Pick<MinimumIdentifiableEntity, "ref"> & {
	firstName: string
	lastName: string
	company: MinimumIdentifiableEntity
}

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isCompanyStaffEntity = (v: any): v is CompanyStaffEntity =>
	"company" in v && "ref" in v && "firstName" in v && "lastName" in v

export type VideoLegacyEntity = MinimumReferentiableEntity & {
	optionalTitle?: string
	generatedTitle?: string
}

export type VideoB2BEntity = VideoLegacyEntity

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isVideoLegacyEntity = (v: any): v is VideoLegacyEntity =>
	("title" in v || "optionalTitle" in v || "generatedTitle" in v) && "ref" in v

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isVideoB2BEntity = (v: any): v is VideoB2BEntity =>
	("title" in v || "optionalTitle" in v || "generatedTitle" in v) && "ref" in v

// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isEntityConnection = (v: any): v is MovieEntity | SeriesEntity => isMovieEntity(v) || isSeriesEntity(v)

export type UserEntity = MinimumIdentifiableEntity & { name: string }
// biome-ignore lint/suspicious/noExplicitAny: typeguard
export const isUserEntity = (v: any): v is AuthorEntity => "ref" in v && "name" in v

export type WidgetEntity = MinimumIdentifiableEntity & { name: string }

// biome-ignore lint/suspicious/noExplicitAny: type guard
export const isWidgetEntity = (v: any): v is WidgetEntity => "url" in v && "type" in v
