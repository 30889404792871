import { DndTable, DndTableRow } from "@components/dnd-table"
import { Modal } from "@components/modal"
import { TablePagination } from "@components/table-pagination"
import { Toggle } from "@components/toggle"
import { useDebounce } from "@hooks/use-debounce"
import { useRankDragAndDrop } from "@hooks/use-rank-drag-and-drop"
import { useStorage } from "@utils/storage"
import { ReorderRank } from "pages/shared/components/reorder-rank-cell"
import { type CountryOption, SelectCountry } from "pages/shared/components/select-country"
import { type LocalizationOption, SelectLocalization } from "pages/shared/components/select-localization"
import { useReducer } from "react"
import { Button, IconButton } from "../../components/button"
import { BoundaryCard, Card, CardHeader, itemPadding } from "../../components/card"
import { FormField } from "../../components/form-field"
import { GlobalEntityLink } from "../../components/global-entity-link"
import { Input } from "../../components/input"
import { ISODateTime } from "../../components/intl"
import { useNotifications } from "../../components/notification"
import { TableActionsCell, TableBody, TableCell, TableHead, TableHeadCell } from "../../components/table"
import { PermissionTarget } from "../../config/permissionTargets"
import {
	useCreateCertificateMutation,
	useDeleteCertificateMutation,
	useGetCertificatesListQuery,
	useUpdateCertificateMutation,
} from "../../graphql/apollo"
import type { CertificateIdentityFragment } from "../../graphql/types"
import { useDocumentTitle } from "../../hooks/use-document-title"
import { DeleteIcon } from "../../icons/delete-icon"
import { EditIcon } from "../../icons/edit-icon"
import { useIsGranted } from "../../utils/auth"
import { EntityMetadataUser } from "../shared/components/entity-metadata"
import { CertificateDelete } from "./certificate-delete"
import { CertificateIdentityForm } from "./components/certificate-identity-form"
import { filtersReducer } from "./utils/filters-reducer"
import { ModalTypes, modalReducer } from "./utils/modal-reducer"
import { useWriteToStorage } from "./utils/use-write-to-storage"

const CERTIFICATES_STORAGE_KEY = "certificates_filters"
const CERTIFICATES_FILTERS = {
	page: 0,
	limit: 30,
	search: undefined,
	country: undefined,
	localization: undefined,
}
type CertificatesFilters = {
	page: number
	limit: number
	search?: string
	country?: CountryOption | undefined
	localization?: LocalizationOption | undefined
}

const Component = () => {
	useDocumentTitle("Certificates")
	const { notify } = useNotifications()
	const isGranted = useIsGranted()

	const filters = useStorage<CertificatesFilters>(CERTIFICATES_STORAGE_KEY, CERTIFICATES_FILTERS)
	const [modalState, modalDispatch] = useReducer(modalReducer<CertificateIdentityFragment>, {
		isOpened: false,
		type: null,
		payload: null,
	})
	const [state, dispatch] = useReducer(filtersReducer, {
		page: filters.page,
		search: filters.search,
		limit: filters.limit,
		country: filters.country,
		localization: filters.localization,
	})
	const [search, { pending: searchDebouncePending }] = useDebounce(state.search)

	const {
		data,
		loading: getSearchLoading,
		refetch,
	} = useGetCertificatesListQuery({
		skip: searchDebouncePending,
		variables: {
			search,
			countryId: state.country?.id,
			limit: state.limit,
			offset: state.limit * state.page,
			limitedLocalizationId: state.localization?.id,
		},
	})
	const certificates = data?.certificates.nodes ?? []

	const [createCertificate, { loading: createCertificateLoading }] = useCreateCertificateMutation({
		onCompleted: () => {
			notify("success", "Certificate created successfully")
			modalDispatch({ type: "reset" })
			refetch()
		},
		onError: () => {
			notify("error", "Certificate could not be created")
		},
	})
	const [deleteCertificate, { loading: deleteCertificateLoading }] = useDeleteCertificateMutation({
		onCompleted: () => {
			notify("success", "Certificate deleted successfully")
			modalDispatch({ type: "reset" })
			refetch()
		},
		onError: () => {
			notify("error", "Certificate could not be deleted")
		},
	})

	const [updateCertificate, { loading: updateCertificateLoading }] = useUpdateCertificateMutation({
		onCompleted: () => {
			notify("success", "Certificate edited successfully")
			modalDispatch({ type: "reset" })
			refetch()
		},
		onError: () => {
			notify("error", "certificate could not be updated")
		},
	})

	useWriteToStorage({ key: CERTIFICATES_STORAGE_KEY, state })

	const [list, handleHover, handleDrop] = useRankDragAndDrop<CertificateIdentityFragment>(certificates, (item, rank) => {
		updateCertificate({
			variables: {
				input: {
					id: item.id,
					rank,
				},
			},
		})
	})
	return (
		<>
			{modalState.type === ModalTypes.CREATE_MODAL && (
				<Modal title="Create certificate" onClose={() => modalDispatch({ type: "reset" })} className={itemPadding}>
					<CertificateIdentityForm
						onSubmit={(certificate) => {
							return createCertificate({
								variables: {
									input: {
										countryId: certificate.country?.id ?? "",
										localizationId: certificate.localization?.id,
										code: certificate.code,
										label: certificate.label,
									},
								},
							})
						}}
					/>
				</Modal>
			)}
			{modalState.type === ModalTypes.DELETE_MODAL && modalState.payload && (
				<Modal title="Delete certificate" onClose={() => modalDispatch({ type: "reset" })} className={itemPadding}>
					<CertificateDelete
						onCancel={() => modalDispatch({ type: "reset" })}
						onSubmit={() => {
							deleteCertificate({ variables: { input: { id: modalState.payload?.id } } })
						}}
					/>
				</Modal>
			)}
			{modalState.type === ModalTypes.EDIT_MODAL && modalState.payload && (
				<Modal title="Edit certificate" onClose={() => modalDispatch({ type: "reset" })} className={itemPadding}>
					<CertificateIdentityForm
						disableCountrySelection
						certificate={modalState.payload ?? null}
						onSubmit={(certificate) => {
							updateCertificate({
								variables: {
									input: {
										id: certificate.certificateId,
										localizationId: certificate.localization?.id,
										code: certificate.code,
										label: certificate.label,
									},
								},
							})
						}}
					/>
				</Modal>
			)}
			<Card
				header={
					<CardHeader
						title="Certificates/ratings"
						actions={
							isGranted(PermissionTarget.CONFIGURATION_RATINGS) && (
								<Button onClick={() => modalDispatch({ type: ModalTypes.CREATE_MODAL })}>Add Certificate</Button>
							)
						}
					/>
				}
			>
				<div className="grid grid-cols-4 gap-4">
					<FormField label="Search" className={itemPadding}>
						<Input value={state.search} onChange={(e) => dispatch({ type: "search", value: e.target.value })} isClearable />
					</FormField>
					<FormField label="Country">
						<SelectCountry
							isClearable
							isSearchable
							value={state.country}
							onChange={(value) => {
								dispatch({
									type: "localization",
									value: null,
								})
								dispatch({
									type: "country",
									value: value ?? undefined,
								})
							}}
						/>
					</FormField>
					<FormField label="Localization">
						<SelectLocalization
							countryId={state.country?.id}
							isClearable
							onChange={(value) => {
								dispatch({
									type: "localization",
									value: value,
								})
							}}
						/>
					</FormField>
				</div>
				<DndTable
					isDraggable={Boolean(state.country?.id)}
					onDrop={handleDrop}
					onHover={handleHover}
					borderBottom={false}
					loading={getSearchLoading || updateCertificateLoading || createCertificateLoading || deleteCertificateLoading}
					layout="auto"
				>
					<TableHead>
						<TableHeadCell className="w-12">Active</TableHeadCell>
						<TableHeadCell className="w-24">Rank</TableHeadCell>
						<TableHeadCell>#ID</TableHeadCell>
						<TableHeadCell>Country</TableHeadCell>
						<TableHeadCell className="w-48">Localization</TableHeadCell>
						<TableHeadCell>Code</TableHeadCell>
						<TableHeadCell>Label</TableHeadCell>
						<TableHeadCell>Created By</TableHeadCell>
						<TableHeadCell>Created At</TableHeadCell>
						<TableHeadCell>Updated By</TableHeadCell>
						<TableHeadCell>Updated At</TableHeadCell>
						<TableHeadCell>Actions</TableHeadCell>
					</TableHead>
					<TableBody>
						{list.map((certificate, i) => (
							<DndTableRow key={`${certificate.id}-${certificate.rank}-${i}`} item={certificate}>
								<TableCell>
									<Toggle
										checked={Boolean(certificate.active)}
										onChange={() => {
											updateCertificate({
												variables: {
													input: {
														id: certificate.id,
														active: !certificate.active,
													},
												},
											})
										}}
									/>
								</TableCell>
								<TableCell>
									<ReorderRank
										disabled={!state.country?.id}
										canReorderDnD={Boolean(state.country?.id)}
										rank={certificate.rank ?? 1}
										inputMaxValue={data?.certificates.totalCount}
										onReorder={(rank) => {
											if (!state.country) return
											updateCertificate({
												variables: {
													input: {
														id: certificate.id,
														rank,
													},
												},
											})
										}}
									/>
								</TableCell>
								<TableCell>
									<GlobalEntityLink entity={certificate} labelType="refLabel" />
								</TableCell>
								<TableCell>{certificate.country.name}</TableCell>
								<TableCell>{certificate.limitedLocalization?.name ?? "-"}</TableCell>
								<TableCell>{certificate.code}</TableCell>
								<TableCell>{certificate.label}</TableCell>
								<TableCell>
									<EntityMetadataUser user={certificate.metadata.createdBy} />
								</TableCell>
								<TableCell>
									<ISODateTime value={certificate.metadata.createdAt} />
								</TableCell>
								<TableCell>
									<EntityMetadataUser user={certificate.metadata.updatedBy} />
								</TableCell>
								<TableCell>
									<ISODateTime value={certificate.metadata.updatedAt} />
								</TableCell>
								<TableActionsCell gap>
									<IconButton
										onClick={() => {
											modalDispatch({
												type: ModalTypes.EDIT_MODAL,
												payload: certificate,
											})
										}}
										title="Edit certificate/rating"
									>
										<EditIcon />
									</IconButton>
									<IconButton
										onClick={() => {
											modalDispatch({
												type: ModalTypes.DELETE_MODAL,
												payload: certificate,
											})
										}}
										title="Delete certificate/rating"
										color="danger"
									>
										<DeleteIcon />
									</IconButton>
								</TableActionsCell>
							</DndTableRow>
						))}
					</TableBody>
				</DndTable>
				<TablePagination
					className={itemPadding}
					count={data?.certificates?.totalCount ?? 0}
					onChangePage={(value) => dispatch({ type: "page", value })}
					onChangeRowsPerPage={(value) => dispatch({ type: "limit", value })}
					page={state.page}
					rowsPerPage={state.limit}
				/>
			</Card>
		</>
	)
}

export const CertificateList = () => {
	return (
		<BoundaryCard>
			<Component />
		</BoundaryCard>
	)
}
