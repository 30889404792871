import * as muiColors from "@mui/material/colors"
import type { Theme } from "@mui/material/styles"
import {
	PALETTE_COLOR_DECLINATIONS_TONES,
	type PaletteColorDeclinationToneValue,
	type PaletteColorDeclinationsTones,
	type PaletteColorDeclinationsTonesKeys,
} from "./types"

const allColorsKeys = Object.keys(muiColors) as (keyof typeof muiColors)[]
type ColorsKeys = (typeof allColorsKeys)[number]
type ColorsDeclinationsTones = (typeof muiColors)[ColorsKeys]

export const commonTheme = () =>
	({
		typography: {
			fontFamily: [
				"ui-sans-serif",
				"system-ui",
				"sans-serif",
				"Apple Color Emoji",
				"Segoe UI Emoji",
				"Segoe UI Symbol",
				"Noto Color Emoji",
			].join(","),
		},
	}) as Partial<Theme>

export const colorsDeclinations = <C extends Partial<ColorsKeys>>(colors: C[]) =>
	colors.reduce(
		(colorsAcc, color) => {
			const mappedColor = muiColors[color]
			colorsAcc[color] = PALETTE_COLOR_DECLINATIONS_TONES.reduce(
				(tonesAcc, tone) => {
					tonesAcc[tone] = mappedColor[tone as keyof ColorsDeclinationsTones]
					return tonesAcc
				},
				{} as Record<PaletteColorDeclinationsTonesKeys, PaletteColorDeclinationToneValue>,
			)
			return colorsAcc
		},
		{} as Record<C, PaletteColorDeclinationsTones>,
	)
