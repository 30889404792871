import { Badge } from "../../../../components/badge"
import { StatusLight } from "../../../../components/status-light"
import { type BrandOptionFragment, Genre, type SeriesSearchEntityFragment } from "../../../../graphql/types"
import { ScreenIcon } from "../../../../icons/screen-icon"
import { formatName } from "../../../../utils/formatters"
import { DEFAULT_CDN_IMAGE, getCDNImage } from "../../utils/cnd-image"

interface SeriesListItemProps {
	series: SeriesSearchEntityFragment
	brand: BrandOptionFragment | undefined
}

const SeriesListItem = ({ series, brand }: SeriesListItemProps) => {
	const genres = series.genres.filter((genre) => genre !== Genre.Unknown)
	const startYear = series?.originalPremiere?.match(/^[\d]{4}/)?.[0]
	const director = series.credits.nodes[0]?.person ?? null
	const poster = series.brandData?.poster ?? series.poster
	const isActiveOnBrand = series.activeOnBrands?.some((element) => element.ref === brand?.ref)

	return (
		<div className="flex gap-3">
			<span className="basis-16 shrink-0">
				<img
					alt="Poster"
					className="rounded aspect-poster object-cover w-16"
					src={poster ? getCDNImage(`/c_74_100/${poster.path}`) : DEFAULT_CDN_IMAGE}
				/>
			</span>
			<div className="flex flex-col gap-1">
				<div className="font-semibold seriess-baseline flex gap-2 text-wrap">
					<span>
						<ScreenIcon className="shrink-0" size="S" />
					</span>
					{brand !== undefined && (
						<span>
							<StatusLight variant={isActiveOnBrand ? "positive" : "negative"} />
						</span>
					)}
					<span>
						{series.title} <span className="text-xs">({series.ref})</span>
					</span>
				</div>

				{startYear ? (
					<div className="flex gap-1 text-xs gray-500">
						<span>{director ? formatName(director) : "Unknown director"}</span>
						<span className="font-semibold">{startYear}</span>
					</div>
				) : null}

				{series.brandData?.title && (
					<div className="flex gap-1 text-xs gray-500">
						<Badge>{series.brandData.brand.ref}</Badge>
						<span className="truncate">{series.brandData.title}</span>
					</div>
				)}

				{genres.length > 0 && (
					<div className="flex flex-wrap gray-500 gap-1">
						{genres.map((genre) => (
							<Badge key={genre}>{genre}</Badge>
						))}
					</div>
				)}
			</div>
		</div>
	)
}

export default SeriesListItem
