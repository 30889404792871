import { PermissionTarget } from "../../../config/permissionTargets"
import type { ViewerQuery } from "../../../graphql/types"
import { type BrandLocaleDataEntity, isBrandLocaleDataEntity } from "../../../types/entities"
import { useIsGranted } from "../../../utils/auth"

export const getEntityBrandLocaleTitles = ({
	entity,
	showLocaleData = false,
	showBrandedData = false,
}: { entity?: BrandLocaleDataEntity | null; showBrandedData?: boolean; showLocaleData?: boolean }) => {
	if (!isBrandLocaleDataEntity(entity)) return []

	const combinedData = []
	if (showBrandedData)
		combinedData.push(...(entity?.brandsData ?? (entity.brandData ? [entity.brandData] : undefined) ?? []))
	if (showLocaleData)
		combinedData.push(...(entity?.localesData ?? (entity.localeData ? [entity.localeData] : undefined) ?? []))

	return [
		...new Set(
			combinedData
				.filter((i): i is { title: string } => i.title !== null && i.title !== entity.title)
				.map(({ title }) => title),
		),
	]
}

export const useEntityBrandLocaleTitles = (
	entity?: BrandLocaleDataEntity | null,
	viewer?: ViewerQuery["viewer"] | null,
): string[] => {
	const isGranted = useIsGranted()
	if (!(viewer && entity)) return []

	const showBrandedData = Boolean(viewer?.allowedBrands?.length || viewer?.defaultBrand?.id)
	const showLocaleData = Boolean(
		viewer?.allowedLocales?.length || viewer?.defaultLocale?.id || isGranted(PermissionTarget.ENTITY_LOCALE_DATA),
	)

	return getEntityBrandLocaleTitles({ entity, showBrandedData, showLocaleData })
}
