import clsx from "clsx"
import { uniqueId } from "es-toolkit/compat"
import { type ReactNode, useMemo } from "react"
import { Tooltip } from "react-tooltip"
import { HelpIcon } from "../icons/help-icon"

type AlertProps = {
	color?: "success" | "info" | "warning" | "error" | "grayed"
	children: ReactNode
	variant?: "default" | "hint" | "tooltip"
	className?: string
	wrapperClassName?: string
}

export const Alert = ({ color = "grayed", variant = "default", className, wrapperClassName, children }: AlertProps) => {
	const uid = useMemo(() => uniqueId("alert_"), [])

	const content = (
		<div
			className={clsx(
				"rounded",
				variant === "default" ? "p-2" : undefined,
				variant === "hint" ? "text-xs italic p-1" : undefined,
				variant !== "tooltip"
					? {
							"text-gray-600 bg-gray-100": color === "grayed",
							"text-emerald-700 bg-emerald-100": color === "success",
							"text-blue-700 bg-blue-100": color === "info",
							"text-amber-700 bg-amber-100": color === "warning",
							"text-red-700 bg-red-100": color === "error",
						}
					: undefined,
				className,
			)}
		>
			{children}
		</div>
	)

	if (variant !== "tooltip") return content

	return (
		<span className={clsx(uid, "hover:cursor-help", wrapperClassName)}>
			<HelpIcon />
			<Tooltip anchorSelect={`span.${uid}`} className="z-10">
				{content}
			</Tooltip>
		</span>
	)
}
